import React from 'react';
import {graphql} from 'gatsby';

import Layout from '../components/Layout';

const index = ({data}) => {
    const {description, keywords, title, url} = data.site.siteMetadata;

    return (
        <Layout
            description={description}
            keywords={keywords}
            title={title}
            lang="en"
            url={url}
        />
    );
};

export const query = graphql`
    query indexQuery {
        site {
            siteMetadata {
                title
                description {
                    de
                    en
                    pl
                }
                keywords {
                    de
                    en
                    pl
                }
                url
            }
        }
    }
`;

export default index;
